<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}พนักงาน</h2>
        <v-form v-model="isFormValid">         
          <v-card class="mt-4">
              <v-card-title>พนักงาน</v-card-title>

              <div class="pa-8">
                <v-form @submit.prevent="submitForm" v-model="isFormValid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formData.name"
                        outlined
                        label="ชื่อ"                               
                        :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                        hide-details="auto"          
                        dense  
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formData.lastname"
                        outlined
                        label="นามสกุล"                               
                        :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                        hide-details="auto"          
                        dense  
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        :url="'/employee/admin/roleList'"                
                        label="กลุ่มสิทธิ์ *"                    
                        outlined
                        v-model="formData.role"
                        item-text="name"
                        item-value="id"
                        dense                
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        return-object
                        hide-details="auto" 
                      >
                      </delay-autocomplete>
                    </v-col>
                  </v-row>       
                </v-form>                       
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.push({ name: 'member' })"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { maxLengthValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
        lastname: null,
        role: null,
        role_id: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.role': function (value) {
      if (!value) return

      const { id } = value

      this.formData.role_id = id
    },
  },
  async created() {
    const admin_id = this.$route.params.admin_id

    if (admin_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Employee'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, lastname, roles } = await asyncGet('/employee/admin/' + admin_id)

      this.formData = {
        name: name,
        lastname: lastname,
        role: !!roles ? roles[0] : null,
        role_id: !!roles ? roles[0].id : null,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const admin_id = this.$route.params.admin_id

        if (admin_id == 'create') await asyncPostAsJson('/employee/admin', this.formData)
        else {
          await asyncPostAsJson('/employee/admin/' + admin_id, { ...this.formData, _method: 'PUT' })
        }

        this.$router.push({ name: 'member' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>